window.OneTrustReady = {
  loaded: false,
  queue: [],

  // Takes a function and calls it when OneTrust is ready
  register: (fn) => {
    if (OneTrustReady.loaded) {
      fn()
    } else {
      OneTrustReady.queue.push(fn)
    }
  },

  // This gets called from the OneTrust wrapper in GTM
  onLoad: () => {
    OneTrustReady.loaded = true
    OneTrustReady.queue.forEach((fn, idx) => {
      // remove function from array so it isn't called again
      OneTrustReady.queue.splice(idx, 1);
      fn()
    })
  }
}
