import { Controller } from 'stimulus'
import { handleSubmitForm } from 'shared/assets/javascript/contact_form_7'

export default class extends Controller {

  connect() {
    this.formTarget = this.element.querySelector('form')
    let requiredFields = this.element.querySelectorAll('[required=required]')

    requiredFields.forEach((el) => {
      let form = el.closest('form')
      let err  = form.querySelector('.form__message.error')
      el.addEventListener('element-invalid', (e) => {
        form.classList.add('-form-error')
        if (err.innerText == '') err.innerText = e.detail
      })
      el.addEventListener('element-valid', (e) => {
        if (err.innerText == e.detail) {
          form.classList.remove('-form-error')
          err.innerText = ''
        }
      })
    })
  }

  async submit(e) {
    e.preventDefault()
    let response = await handleSubmitForm(this.formTarget)
    console.log({response})
    if (!response || response.status !== 'mail_sent') return
    this.element.classList.add('-form-success')
    this.element.querySelector('#email').value = ''
    setTimeout(() => {
      this.element.classList.remove('-form-success')
    }, 5000)
  }

}
