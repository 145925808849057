/* eslint no-console:0 */
// This is the entrypoint for the `roxy-cinema` theme. The files
// imported here will be included on every page of the roxy-cinema website.
//
// To reference this file, add <%= javascript_pack_tag 'roxy-cinema' %> to the appropriate
// layout file, like frontend/roxy-cinema/layouts/application.haml

import 'shared/assets/css'
import 'roxy-cinema/components/header/header'
import 'roxy-cinema/components/footer/footer'
import 'roxy-cinema/components/newsletter_signup/newsletter_signup'
import 'roxy-cinema/components/popup_notification/popup_notification'
import 'shared/components'
import 'shared/assets/javascript'
import './css'
import './javascript'
