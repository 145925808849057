import { Controller } from 'stimulus'
import moment from 'moment'

export default class extends Controller {
  connect() {
    if (!this.element.dataset.cookieDuration) {
      // remove cookie - set old expiry
      this.setCookie('diner_popup_notification', 'Thu, 01 Jan 1970 00:00:01 GMT')
    }
    this.cookie = this.getCookie('diner_popup_notification')
    if (this.showPopup()) {
      document.body.classList.add('-is-open-modal')
    }
    if (this.shouldSetCookie()) {
      let date = moment().add(this.element.dataset.cookieDuration, 'days').toDate()
      this.setCookie('diner_popup_notification', date)
    }
  }

  closeModal(e) {
    e.preventDefault()
    this.element.classList.remove('active')
    document.body.classList.remove('-is-open-modal')
  }

  showPopup() {
    return !this.cookie
  }

  shouldSetCookie() {
    return this.element.dataset.cookieDuration && !this.cookie
  }

  getCookie(name) {
    return document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`))
      ?.split("=")[1]
  }

  setCookie(name, expires = 'Fri, 31 Dec 9999 23:59:59 GMT') {
    document.cookie = `${name}=true; expires=${expires}`
  }

}
