const validateElement = (el) => {
  let msg = el.previousElementSibling
  if (!msg || !msg.classList.contains('form__message--error')) {
    msg = el.nextElementSibling
  }
  let eventType = 'valid'
  if (el.checkValidity()) {
    el.classList.remove('-error')
    msg.classList.remove('active')
  } else {
    eventType = 'invalid'
    el.classList.add('-error')
    msg.classList.add('active')
  }
  let event = new CustomEvent(`element-${eventType}`, {
    bubbles: true,
    detail: msg.innerText.trim()
  })
  el.dispatchEvent(event)
}

export async function handleSubmitForm(target){
  let form = new FormData(target)
  let requiredFields = target.querySelectorAll('[required=required]')

  requiredFields.forEach((el) => {
    el.addEventListener('input', () => validateElement(el))
    validateElement(el)
  })

  if (!target.checkValidity()) {
    console.log('invalid form')
    return
  }
  return await submitContactForm(form)
}

export async function submitContactForm(payload) {
  let id = payload.get('_wpcf7')
  let url = payload.get('admin_url')
  url = `${url}/wp-json/contact-form-7/v1/contact-forms/${id}/feedback/`
  let response = await fetch(url, {
    method: 'POST',
    body: payload
  })
  return response.json()
}
