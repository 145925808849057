import Dropkick from 'dropkickjs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['otherSites'];

  initialize() {
    new Dropkick(this.otherSitesTarget, {
      mobile: true,
      change() {
        if (!!this.value) window.open(this.value, '_blank');
      },
    });
  }
}
