import { Controller } from 'stimulus';

export default class extends Controller {

  skip_link() {
    // FIXME
    // e.preventDefault()
    // $('main').children().eq(1).attr('tabindex', '-1').focus()
    document.querySelector('main section:nth-child(2)').focus()
  }

}
